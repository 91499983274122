<template>


  <SkBasicForm 
    v-if="userBannedDataStatus === 'loading'"
    />

  <div v-else-if="userBannedData?.items_room || userBannedData?.items_user" class="modal_user_content">

    <div class="content_profile" >

      <div class="settings_description">

        <div class="followingList">
          <template v-for="pro in userBannedData.items_user">
            <UserSettingsBannedItem
              :data="pro"
              :userBannedData="userBannedData"
              :user-id="props.userId"
              type="user"
              />
          </template>
          <template v-for="pro in userBannedData.items_room">
            <UserSettingsBannedItem
              :data="pro"
              :userBannedData="userBannedData"
              :user-id="props.userId"
              type="room"
              />
          </template>
        </div>
  
      </div>

    </div>

  </div>


</template>

<script setup lang="ts">

import type { User } from '@/types/user'

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api} = useNuxtApp()

interface Props {
  user: User,
  userId: number,
}

const props = defineProps<Props>();

const loadData = async function(id: number) {   
  const response = await $api.getGrowerSettings(id, 'banned')  
  return response;  
}


const { sttus: userBannedDataStatus,  data: userBannedData } = await useLazyAsyncData('userBannedData', async () => await loadData(props.userId))





</script>

<style scoped>

.content_profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.modal_user_content .settings_description {
    display: inline-block;
    vertical-align: middle;
    margin-right: auto;
    width: 100%;
}

.modal_user_content .settings_description .row{
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.followingList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.followingList.centered {
}

</style>
